import { render, staticRenderFns } from "./WatchesList.vue?vue&type=template&id=32aa2d88&scoped=true&"
import script from "./WatchesList.vue?vue&type=script&lang=js&"
export * from "./WatchesList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32aa2d88",
  null
  
)

export default component.exports